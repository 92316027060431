import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import About from "../components/Repeating/About";
import CallToAction from "../components/Repeating/CTA";
import BusinessServiceSidebar from "../components/Repeating/BusinessServiceSidebar";
import BusinessServiceSlider from "../components/Repeating/BusinessServiceSlider";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="IRS Representation Services | DC, MD & VA | DeBlanc"
        description="Are your tax returns being examined? Don’t panic. DeBlanc + Murphy can act as your representative with the IRS. Serving the greater Washington, DC area."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="pt-20 pb-10 md:py-14">
        <div className="container">
          <div className="max-w-[800px]">
            <h1>IRS Representation Services</h1>
            <p className="mb-0 md:text-xl">
              Are your tax returns being examined? Don’t panic. DeBlanc, Murphy & Murphy
              can act as your representative with the IRS. Serving the greater
              Washington, DC area.
            </p>
          </div>
        </div>
      </section>

      <section className="relative mb-4 h-[375px] md:mb-32 md:h-[504px]">
        <BgImage
          image={getImage(data.parallaxImage.childImageSharp.gatsbyImageData)}
          className="h-full w-full bg-fixed"
        ></BgImage>
        <div className="gradient-blend absolute bottom-0 h-28 w-full"></div>
      </section>

      <section className="relative mb-20 md:mb-32">
        <div className="container">
          <div className="grid gap-y-20 md:grid-cols-12">
            <div className="md:col-span-8 md:col-start-1">
              <div className="mb-16">
                <h2>Did You Receive a Letter from the IRS?</h2>
                <p>
                  As your representative, we’ll handle all communications with
                  the IRS on your behalf.
                </p>
                <p>
                  We have extensive experience working with IRS revenue agents,
                  officers, supervisors, and managers. And because the IRS knows
                  us and respects our reputation, our firm has a high rate of
                  favorable outcomes.
                </p>
              </div>

              <div className="mb-16">
                <h3>Who We Represent</h3>
                <p>
                  We’re skilled in representing all types of taxpayers,
                  including:
                </p>
                <ul className="styled-list-plus mb-6">
                  <li>Individuals</li>
                  <li>Corporations</li>
                  <li>Partnerships</li>
                  <li>Fiduciaries</li>
                  <li>Limited Liability Companies</li>
                  <li>Limited Liability Partnerships</li>
                  <li>Estates</li>
                  <li>Associations</li>
                  <li>Nonprofit Entities</li>
                  <li>Retirement Plans</li>
                </ul>
              </div>

              <div>
                <h3>Full Tax Representation</h3>
                <p>
                  We’ve represented clients dealing with the IRS at all levels,
                  including appeals. Our tax experts are qualified to represent
                  you in many matters, including:
                </p>
                <ul className="styled-list-plus mb-6">
                  <li>Audits</li>
                  <li>Examinations</li>
                  <li>Interrogatories / Depositions</li>
                  <li>Collections</li>
                  <li>Enforcements</li>
                  <li>Liens</li>
                  <li>Levies</li>
                  <li>
                    Offers in Compromise (settling tax debt for less than the
                    full amount you owe)
                  </li>
                  <li>Stays (temporarily delaying collections)</li>
                  <li>Garnishments</li>
                </ul>

                <ButtonSolid modal="modal-contact" text="Get a Quote" />
              </div>
            </div>
            <div className="hidden md:col-span-4 md:col-end-13 md:block">
              <BusinessServiceSidebar activeService={6} />
            </div>
          </div>
        </div>
      </section>

      <Testimonials />
      <BusinessServiceSlider hideService={6} />
      <About paddingTop={true} />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    parallaxImage: file(
      relativePath: { eq: "2.6 Business IRS Representation/1.0 Hero.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

export default Page;
